/**
 * ReportPage
 * @module pages/ReportPage
 */

import React, { useState, useContext, useEffect } from 'react';
import './ReportPage.scss';
import { Link } from 'react-router-dom';

import { FormContext } from 'context/form.context';
import { useStepCounter, useFormSubmit } from 'common/customHooks';

import LinkButton from 'components/Button';
import Loader from 'components/Loader';
import { Form, TextArea, Select, RadioButton } from 'components/Form';
import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import MobileHeader from 'components/MobileHeader';
import ReportSummary from './ReportSummary';

import DatePicker from 'react-datepicker';
import './datepicker.scss';
import sv from 'date-fns/locale/sv';

const stepIdNames = {
	1: { name: 'leagueTypes', form: 'leagueTypeId' },
	2: { name: 'reportPositions', form: 'positionId' },
	3: { name: 'reportIncidents', form: 'incidentId' },
	4: { name: 'reportTypes', form: 'typeId' },
	5: { name: 'reportDate', form: 'date' },
	6: { name: 'reportEvents', form: 'eventId' },
	0: { name: 'organizations', form: 'organizationId' },
};

const getCurrentTitle = (titles, currentStepId) =>
	titles.filter(
		item => item.area.name.toLowerCase() === currentStepId.toLowerCase()
	)[0];

const renderFieldset = (options, name, stepNr) => {
	return (
		<fieldset className="form__fieldset">
			<legend className="is-visuallyhidden">{`Alternativ för formulärsteg nr ${stepNr}`}</legend>
			{options.map((opt, i) => {
				return (
					<div className="form__item form__item--Radio" key={i}>
						<label htmlFor={opt.id}>
							<RadioButton
								type="radio"
								name={name}
								value={opt.id.toString()}
								id={opt.id}
							/>
							{opt.name}
						</label>
					</div>
				);
			})}
		</fieldset>
	);
};

const ReportPage = ({ location, match, history }) => {
	const { params } = match;
	const onMobileDevice = window.matchMedia('(max-width: 767px)').matches;
	const {
		setValues,
		values,
		questions,
		titles,
		getQuestions,
		getTitles,
	} = useContext(FormContext);

	const [showLinkBtn, setShowLinkBtn] = useState(false);
	const [date, setDate] = useState(values.date);

	const [currentStep, setCurrentStep, nextStep, previousStep] = useStepCounter(
		params.id
	);
	const [submitError, submitForm] = useFormSubmit(history);

	const currentStepId = stepIdNames[currentStep];

	useEffect(() => {
		if (!params.id) {
			setCurrentStep(1);
		}

		if (questions === null) {
			getQuestions();
			getTitles();
		}
	});

	const currentStepTitle =
		titles && currentStepId ? getCurrentTitle(titles, currentStepId.name) : {};

	const handleFormSubmit = values => {
		const formValues = { ...values, date };
		submitForm(formValues, '/bekraftelse');
	};

	const showNextBtnCondition =
		currentStep === 5 ? date !== null : currentStep !== 7;

	return (
		<StandardLayout location={location}>
			{onMobileDevice && (
				<MobileHeader
					title="Rapportera skada"
					prevPath={currentStep === 1 ? '/' : `/rapportera-${previousStep}`}
					onClick={() => setCurrentStep(previousStep)}
				/>
			)}
			<main id="main-content" className="reportpage">
				{questions === null ? (
					<Loader />
				) : (
					<Layout>
						{!onMobileDevice && (
							<div className="reportpage__link">
								<Link
									to={currentStep === 1 ? '/' : `/rapportera-${previousStep}`}
									onClick={() => setCurrentStep(previousStep)}
								>
									← Tillbaka
								</Link>
							</div>
						)}

						<Form
							onSubmit={handleFormSubmit}
							onChange={(field, values) => {
								setValues(values);
								setShowLinkBtn(currentStep);
							}}
							initialValues={{ ...values }}
							className={`form ${currentStep !== 7 ? 'form--center' : ''}`}
						>
							<div className="form__wrapper">
								<h1 className="form__headline">
									{currentStep !== 7
										? currentStepTitle.header
										: 'Sammanställning'}
								</h1>

								{currentStepTitle.header && currentStepTitle.description && (
									<p className="form__description">
										{currentStepTitle.description}
									</p>
								)}

								{currentStep === 1 &&
									renderFieldset(
										questions['leagueTypes'],
										'leagueTypeId',
										currentStep
									)}
								{currentStep === 2 &&
									renderFieldset(
										questions['reportPositions'],
										'positionId',
										currentStep
									)}
								{currentStep === 3 &&
									renderFieldset(
										questions['reportIncidents'],
										'incidentId',
										currentStep
									)}
								{currentStep === 4 &&
									renderFieldset(
										questions['reportTypes'],
										'typeId',
										currentStep
									)}

								{currentStep === 5 && (
									<div className="form__item form__item--Text">
										<DatePicker
											maxDate={new Date()}
											customInput={<CustomInput />}
											selected={date}
											placeholderText="Välj datum"
											onChange={newDate => {
												setDate(newDate);
												setShowLinkBtn(currentStep);
											}}
											dateFormat="d MMMM yyyy"
											popperClassName="reportpage__calendar"
											popperModifiers={{
												flip: {
													behavior: ['bottom'],
												},
											}}
											locale={sv}
											isClearable={true}
										/>
									</div>
								)}

								{currentStep === 6 && (
									<>
										{renderFieldset(
											questions['reportEvents'],
											'eventId',
											currentStep
										)}
										{values['eventId'] && values['eventId'] === '6' && (
											<div className="form__item form__item--Textarea">
												<label
													htmlFor="eventOther"
													className="is-visuallyhidden"
												>
													Ange annan händelse
												</label>
												<TextArea
													id="eventOther"
													placeholder="Ange händelse"
													name="eventOther"
												/>
											</div>
										)}
									</>
								)}

								{currentStep === 7 && (
									<ReportSummary date={date} submitError={submitError} />
								)}
							</div>

							{showNextBtnCondition &&
								(showLinkBtn === currentStep || values[currentStepId.form]) && (
									<div className="reportpage__button">
										<LinkButton
											type="route"
											url={`/rapportera-${nextStep}`}
											color="blue"
											onClick={() => setCurrentStep(nextStep)}
										>
											Ok, nästa
										</LinkButton>
									</div>
								)}
						</Form>
					</Layout>
				)}
			</main>
		</StandardLayout>
	);
};

/** Custom input for DatePicker to prevent input field jumping in mobile */
class CustomInput extends React.Component {
	render() {
		const value = this.props.value === '' ? 'Välj datum' : this.props.value;

		return (
			<button
				type="button"
				className="reportpage__calendarbtn"
				onClick={this.props.onClick}
			>
				{value}
			</button>
		);
	}
}

export default ReportPage;
